import React, { useContext } from "react";
import styled from "styled-components";
import HamburgerMenuIcon from "../../assets/svgs/hamburger_menu.svg";
import AppContext from "../../AppContext";

const MenuContainer = styled.div`
  height: 100%;
  svg {
    cursor: pointer;
    display: block;
    overflow: visible;
    width: 40px;
    height: 100%;
    path {
      stroke: ${(props) => props.theme.colors.text};
      stroke-width: 5;
    }
  }
`;

const HamburgerMenu: React.FC = () => {
  const { toggleNav } = useContext(AppContext);
  return (
    <MenuContainer onClick={toggleNav}>
      <HamburgerMenuIcon />
    </MenuContainer>
  );
};

export default HamburgerMenu;

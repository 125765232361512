import { Link } from "gatsby";
import styled from "styled-components";

const UnstyledLink = styled(Link)`
  color: unset;
  text-decoration: none;
  &:visited {
    color: unset;
  }
`;

export default UnstyledLink;

import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../../useSiteMetadata";
import { useStaticQuery, graphql } from "gatsby";

type OgImageQueryResult = {
  imageSharp: {
    fixed: {
      src: string;
    };
  };
};
type SeoProps = {
  title: string;
  path?: string;
  description?: string;
  type?: "article" | "website";
  image?: string;
};

const Seo: React.FC<SeoProps> = ({ title, description, path, type, image }) => {
  const { siteURL, description: defaultDescription } = useSiteMetadata();
  const { imageSharp } = useStaticQuery<OgImageQueryResult>(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "og-default-image.png" } }) {
        fixed {
          src
        }
      }
    }
  `);
  return (
    <Helmet
      meta={[
        { property: "og:title", content: title },
        { property: "twitter:title", content: title },
        {
          property: "og:description",
          content: description ?? defaultDescription,
        },
        {
          property: "twitter:description",
          content: description ?? defaultDescription,
        },
        {
          property: "og:url",
          content: `${siteURL}${path ?? ""}`,
        },
        {
          property: "og:image",
          content: `${siteURL}${imageSharp.fixed.src}`,
        },
        {
          property: "og:image:width",
          content: `1200`,
        },
        {
          property: "og:image:height",
          content: `630`,
        },
        {
          property: "twitter:image",
          content: `${siteURL}${imageSharp.fixed.src}`,
        },
        {
          property: "twitter:image:width",
          content: `1200`,
        },
        {
          property: "twitter:image:height",
          content: `630`,
        },
        {
          property: "og:type",
          content: type ?? "website",
        },
        {
          property: `twitter:card`,
          content: `summary_large_image`,
        },
      ]}
    />
  );
};

export default Seo;

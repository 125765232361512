import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Logo from "../../assets/svgs/logo.svg";
import HeaderLinks from "./HeaderLinks";
import routes from "../../routes";
import UnstyledLink from "./UnstyledLink";

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  height: 100px;
  background: #d3ddd4;
  box-sizing: border-box;
`;

const NavTitle = styled.h1`
  margin: 0;
  margin-left: 5px;
  font-size: 1.5em;
  font-weight: ${(props) => props.theme.fonts.weights.regular};
`;

const NavLogoContainer = styled.div`
  height: 60px;
  display: flex;
`;

const LogoLink = styled(Link)`
  display: block;
  height: 100%;
  svg {
    height: 100%;
    width: auto;
    * {
      stroke-width: 18;
    }
  }
`;

const Header: React.FC = () => {
  return (
    <Nav>
      <NavLogoContainer>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        <UnstyledLink to="/">
          <NavTitle>
            Foodie Buddies
            <br />
            Podcast
          </NavTitle>
        </UnstyledLink>
      </NavLogoContainer>
      <HeaderLinks routes={routes} />
    </Nav>
  );
};

export default Header;

import { useStaticQuery, graphql } from "gatsby";

type SiteMetadataQueryResult = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      siteURL: string;
    };
  };
};

const useSiteMetadata = () => {
  const { site } = useStaticQuery<SiteMetadataQueryResult>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteURL
        }
      }
    }
  `);
  return site.siteMetadata;
};

export default useSiteMetadata;

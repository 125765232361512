import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import HamburgerMenu from "./HamburgerMenu";

const HamburgerMenuContainer = styled.div`
  height: 25px;
  display: block;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const Menu = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }

  a {
    position: relative;
    margin-left: 1.5em;
    font-size: 1.1em;
    color: ${(props) => props.theme.colors.text};
    font-family: ${(props) => props.theme.fonts.families.headers};
    text-decoration: none;

    &:visited {
      color: ${(props) => props.theme.colors.text};
    }

    &[aria-current="page"] {
      cursor: default;
    }

    &:not([aria-current="page"]) {
      color: hsl(126, 20%, 32%);
      &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        width: 0%;
        transition: width 0.2s ease;
        margin: 0 auto;
        height: 2px;
        background: ${(props) => props.theme.colors.text};
      }
      &:hover {
        color: ${(props) => props.theme.colors.text};
        &:after {
          width: 90%;
        }
      }
    }
  }
`;

type Route = {
  name: string;
  path: string;
};

type HeaderLinksProps = {
  routes: Route[];
};

const HeaderLinks: React.FC<HeaderLinksProps> = ({ routes }) => {
  return (
    <>
      <Menu>
        {routes.map((route) => (
          <Link key={route.path} to={route.path}>
            {route.name}
          </Link>
        ))}
      </Menu>
      <HamburgerMenuContainer>
        <HamburgerMenu />
      </HamburgerMenuContainer>
    </>
  );
};

export default HeaderLinks;
